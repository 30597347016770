import Vue from "vue"
import VueI18n from "vue-i18n"
Vue.use(VueI18n)
/*
function loadLocaleMessages() {
  let messages = {}
  fetch('/api/i18n/cleaner_signup.json').then((response) => {
    for(var localeKey in response.data) {
      let translations = response.data[localeKey]
      messages[localeKey] = translations
    }
    return messages
  })
}
*/
export default new VueI18n({
  locale: "en",
  fallbackLocale: "en",

  //// there should not be any warning, but it seems we are using the
  //// translations before they are actually loaded
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  //messages: loadLocaleMessages()
})
